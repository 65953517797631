<template>
  <div>
    <div class="card-toolbar mb-5">
      <button @click="openAdvancedSearch" class="btn btn-outline-primary font-weight-bolder ml-5">
        <span>
          <i class="la la-filter"></i>
          <span>{{ $t("advanced_filter") }}</span>
        </span>
      </button>
    </div>

    <div class="card card-custom mb-5" v-if="showAdvancedSearch">
      <div class="card-body">
        <div class="m-form m-form--fit m--margin-bottom-20">
          <div class="row">
            <div class="form-group col-md-6">
              <label for="name">{{ $t("reports_agents_dues.agent_name") }}</label>
              <multiselect
                  v-model="agent"
                  :placeholder="$t('reports_agents_dues.agent_name')"
                  label="name"
                  track-by="id"
                  :options="agents"
                  :multiple="false"
                  :taggable="false"
                  :show-labels="false"
                  :show-no-options="false"
                  :show-no-results="false"
                  :internal-search="false"
              >
              </multiselect>
            </div>
            <div class="form-group col-md-6 mt-2 mb-2">
              <label for="from_date">{{$t("reports_agents_dues.from_date")}}</label>
              <input v-model="filters.from_date" type="date" id="from_date" class="form-control"/>
            </div>
            <div class="form-group col-md-6 mt-2 mb-2">
              <label for="to_date">{{$t("reports_agents_dues.to_date")}}</label>
              <input v-model="filters.to_date" type="date" id="to_date" class="form-control"/>
            </div>

            <div class="form-group d-inline-flex col-md-6 mt-10">
              <button type="button" @click="doFilter" class="btn btn-primary mt-auto mr-2" id="m_search">
                <span><i class="fas fa-search"></i>
                  <span>{{ $t("search") }}</span>
                </span>
              </button>
              <button type="button" @click="resetFilter" class="btn btn-danger mt-auto mr-2" id="m_reset">
                <span>
                  <i class="fas fa-trash-restore"></i>
                  <span>{{ $t("reset_search") }}</span>
                </span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--begin::User-->
    <div class="card card-custom">
      <div class="card-body">
        <div class="mb-5">
          <div class="d-flex justify-content-end">
            <custom-export-data
                :data-list="dataList"
                :route="mainRoute"
                :filters="filters"
                :fields="json_fields"
                :file-name="$t('MENU.reports_agents_dues')"
            ></custom-export-data>
          </div>
        </div>

        <v-server-table
            :class="'dataTable table-row-dashed'"
            :columns="columns"
            :options="options"
            ref="table"
        >
        </v-server-table>

        <!--end: Datatable-->
      </div>
    </div>
    <!--end::User-->
  </div>
</template>

<script>
import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
import ApiService from "@/core/services/api.service";

export default {
  components: {},
  data() {
    return {
      mainRoute: "/agents/reports_agents_dues",
      mainRouteDependency: "base/dependency",

      showAdvancedSearch: false,

      filters: {
        agent_id: null,
        from_date: null,
        to_date: null,
      },

      columns: [
        "agent_name",
        "received_amount",
        "remaining_amount",
        "total_amount",
      ],

      validation: [],
      dataList: [],
      agents: [],
      agent: null,
    };
  },
  computed: {
    json_fields: function () {
      let fields = {};
      fields[this.$t("reports_agents_dues.agent_name")] = "agent_name";
      fields[this.$t("reports_agents_dues.received_amount")] = "received_amount";
      fields[this.$t("reports_agents_dues.remaining_amount")] = "remaining_amount";
      fields[this.$t("reports_agents_dues.total_amount")] = "total_amount";

      return fields;
    },
    options: function () {
      let that = this;
      return {
        texts: {
          loadingError: that.$t("Something_went_wrong"),
          filter: "",
          limit: that.$t("records"),
          filterBy: that.$t("Filter") + " {column}",
          count: " ",
          filterPlaceholder: that.$t("Search_query"),
          loading: that.$t("Loading") + "...",
        },
        headings: {
          agent_name: that.$t("reports_agents_dues.agent_name"),
          received_amount: that.$t("reports_agents_dues.received_amount"),
          remaining_amount: that.$t("reports_agents_dues.remaining_amount"),
          total_amount: that.$t("reports_agents_dues.total_amount"),
        },
        sortable: ["created_at"],
        filterByColumn: false,
        filterable: false,
        customFilters: [
          {
            name: "alphabet",
          },
        ],
        orderBy: {column: "created_at"},

        alwaysShowPerPageSelect: true,
        perPage: 10,
        pagination: {chunk: 5, dropdown: false},
        skin: "table align-middle table-row-dashed fs-5 gy-5 text-center dataTable dtr-inline",
        sortIcon: {
          base: "fas ml-2",
          up: "fa-sort-alpha-up",
          down: "fa-sort-alpha-down",
          is: "fa-sort",
        },
        perPageValues: [5, 10, 15, 25],

        requestFunction(data) {
          let _params = {
            ascending: 0,
            byColumn: data.byColumn,
            limit: data.limit,
            orderBy: data.orderBy,
            page: data.page,
            filter: data.query,
            ...that.filters,
          };
          return ApiService.query(that.mainRoute, {..._params});
        },
        responseAdapter(resp) {
          that.dataList = resp.data.data.data;
          return {
            data: resp.data.data.data,
            count: resp.data.data.total,
          };
        },
      };
    },
  },
  watch: {
    agent: function (val) {
      if (val) {
        this.filters.agent_id = val.id;
      } else {
        this.filters.agent_id = null;
      }
    },
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{title: this.$t("MENU.reports_agents_dues")},]);
    this.getAgents();
  },
  methods: {
    getFetch() {
      this.$refs.table.refresh();
    },
    openAdvancedSearch() {
      this.showAdvancedSearch = !this.showAdvancedSearch;
    },
    doFilter() {
      this.$refs.table.refresh();
    },
    resetFilter() {
      this.filters.from_date = null;
      this.filters.to_date = null;
      this.filters.agent_id = null;
      this.agent = null;

      this.$refs.table.refresh();
    },

    getAgents() {
      ApiService.get(this.mainRouteDependency + "/agents").then((response) => {
        this.agents = response.data.data;
      });
    },
  },
};
</script>
